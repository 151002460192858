<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="subtitle-1 ma-0">Lista de Classificações</p>
        <v-btn
          v-if="checkPermission('classification.create')"
          small
          depressed
          color="fmq_gray"
          dark
          to="/Classificacao-criar"
          >Nova Classificação</v-btn
        >
      </div>
      <ClassificacaoTabela
        :headers="headers"
        :items="data"
        :total="total"
        :page="formData.page"
        :pageText="pageText"
        :loading="loading"
        @handleFilter="filter"
        @excluir="excluirModal"
      >
        <template v-slot:name>
          <v-text-field
            v-model="formData.name"
            placeholder="Busque pelo nome"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              buscar(formData);
              formData.page = 1;
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </ClassificacaoTabela>
    </div>
    <AlertConfirmation
      :dialog="dialog"
      :dialogMessage="dialogMessage"
      @close="dialog = false"
      @accept="excluir"
    />
    <AlertSuccess
      :dialog="success"
      dialogMessage="Classificação excluida com sucesso"
      @close="
        buscar(formData);
        success = false;
      "
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { checkPermission } from "@/utils";
import ClassificacaoTabela from "@/components/classificacoes/ClassificacaoTabela.vue";
import {
  searchClassification,
  deleteClassification,
} from "@/services/classification.js";
export default {
  name: "Classificacoes",
  components: { ClassificacaoTabela },
  data: () => ({
    breadcrumbs: [
      {
        text: "Classificações",
        disabled: true,
        to: "",
      },
    ],
    headers: [
      { text: "Nome", value: "name", width: "90%" },
      { text: "Ações", value: "id", sortable: false },
    ],
    formData: {
      sort: null,
      perPage: 20,
      page: 1,
      name: null,
      code: null,
    },
    data: [],
    total: null,
    pageText: null,
    loading: false,
    dialog: false,
    dialogMessage: null,
    itemId: null,
    error: false,
    success: false,
    messageError: null,
  }),
  created() {
    if (this.$store.state.searchClassificationHistory.length) {
      this.formData = {
        ...this.$store.state.searchClassificationHistory[0],
      };
    }
    this.buscar(this.formData);
  },
  methods: {
    checkPermission,
    async buscar(payload) {
      this.data = [];
      this.$store.dispatch("setSearchClassificationHistory", payload);
      try {
        this.loading = true;
        const resp = await searchClassification(payload);
        this.data = resp.data;
        this.total = resp.meta.total;
        this.pageText = `${resp.meta.from}-${resp.meta.to} de ${resp.meta.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async excluir() {
      this.error = false;
      this.sucess = false;
      try {
        await deleteClassification(this.itemId).then(() => {
          this.dialog = false;
          this.success = true;
        });
      } catch (e) {
        this.dialog = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    excluirModal(item) {
      this.dialogMessage = `Tem certeza que deseja excluir ${item.name}?`;
      this.dialog = true;
      this.itemId = item.id;
    },
    filter(event) {
      this.$store.dispatch("setSearchClassificationHistory", this.formData);
      if (event.orderBy) {
        this.formData.sort = event.orderBy + event.orderSorted;
      } else {
        this.formData.sort = null;
      }
      this.formData.perPage = event.perPage;
      this.formData.page = event.page;
      this.buscar(this.formData);
    },
    clear() {
      this.$store.dispatch("setSearchClassificationHistory", {});
      this.formData.name = null;
      this.formData.page = 1;
      this.buscar(this.formData);
    },
    search() {
      this.formData.page = 1;
      this.buscar(this.formData);
    },
  },
};
</script>
